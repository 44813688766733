import { HealthApi, formatResponse, gql } from '../apis'
import type { IGraphqlApi, IColumnService } from '../interfaces'
import { type IColumn } from '../models'

export class ColumnService implements IColumnService {
  constructor (private readonly api: IGraphqlApi = HealthApi) {}

  async list (after?: string, columnTypeId?: number | null) {
    try {
      const response = await this.api.query({
        query: LIST_COLUMN_QUERY,
        variables: {
          first: 16,
          after: after || '',
          columnTypeId: columnTypeId || null
        }
      })

      return {
        ...formatResponse({
          data: response.data?.columns?.edges?.map((e: any) => e.node) as IColumn[],
          errors: response.errors
        }),
        after: response.data?.columns?.pageInfo?.hasNextPage
          ? response.data?.columns?.pageInfo?.endCursor
          : ''
      }
    } catch (err: any) {
      return formatResponse({
        data: [],
        errors: [err]
      })
    }
  }
}

const LIST_COLUMN_QUERY = gql`query ListColumns($first: Int!, $after: String, $columnTypeId: Int) {
  columns(first: $first, after: $after, column_type_id: $columnTypeId) {
    edges {
      node {
        id
        name
        photo_url
        tags
        created_at
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}`
