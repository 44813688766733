import { makeAutoObservable, runInAction } from 'mobx'
import type { IAppStore } from '../config/interfaces'
import type { IMealType, IUserService } from '../lib/interfaces'
import type { IDashboardBodyRecord, IDashboardMeal, IDashboardStore } from './interfaces'
import { getDataFieldAvg, getGroupRangeByMode, groupItemsByCreatedAt } from '../lib/helpers'
import { type IBodyRecord, type IMeal } from '../lib/models'
import dayjs from 'dayjs'

export class DashboardStore implements IDashboardStore {
  errors: string[] = []
  meals: IDashboardMeal[] = []
  bodyRecords: IDashboardBodyRecord[] = []

  chartLabels: string[] = []
  chartFatPercents: Array<number | undefined> = []
  chartWeights: Array<number | undefined> = []

  mealTypes: Map<IMealType, boolean> = new Map<IMealType, boolean>([
    ['breakfast', false],
    ['lunch', false],
    ['dinner', false],
    ['snack', false]
  ])

  private nextMealCursor = ''

  constructor (private readonly store: IAppStore, private readonly service: IUserService) {
    makeAutoObservable(this)
  }

  get hasMoreMeals () {
    return Boolean(this.nextMealCursor)
  }

  async fetchDashboardData () {
    this.errors = []

    const { data, errors } = await this.service.getDashboardData()

    this.errors = errors

    if (!errors.length) {
      const bodyRecords = data?.bodyRecords || []

      runInAction(() => {
        this.meals = this.prepareMeals(data?.meals?.data)
        this.nextMealCursor = data?.meals?.after || ''
        this.bodyRecords = bodyRecords

        this.prepareChartData(bodyRecords)
      })
    }
  }

  async loadMoreMeals () {
    if (!this.hasMoreMeals) {
      return
    }

    const meals = await this.fetchMeals(this.nextMealCursor)

    runInAction(() => {
      this.meals = this.meals.concat(this.prepareMeals(meals))
    })
  }

  async loadMeals () {
    const meals = await this.fetchMeals()

    runInAction(() => {
      this.meals = this.prepareMeals(meals)
    })
  }

  private async fetchMeals (after?: string) {
    this.errors = []

    const mealTypes: IMealType[] = []

    this.mealTypes.forEach((enabled, type) => {
      if (enabled) {
        mealTypes.push(type)
      }
    })

    const { data, after: nextCursor, errors } = await this.service.listMeals(
      after,
      mealTypes
    )

    if (!errors.length) {
      runInAction(() => {
        this.errors = errors
        this.nextMealCursor = after !== nextCursor ? nextCursor || '' : ''
      })

      return data || []
    } else {
      runInAction(() => {
        this.errors = errors
      })

      return data
    }
  }

  private prepareChartData (bodyRecords: IBodyRecord[]) {
    const groupedBodyRecords = groupItemsByCreatedAt(bodyRecords)
    const chartLabels = getGroupRangeByMode('year')
    const chartKeys = Object.keys(chartLabels)
    const chartFatPercents = Array(chartKeys.length).fill(undefined)
    const chartWeights = Array(chartKeys.length).fill(undefined)

    Object.keys(groupedBodyRecords).forEach(key => {
      const chartDataIdx = chartKeys.indexOf(key)

      if (chartDataIdx > -1) {
        chartFatPercents[chartDataIdx] = Math.round(getDataFieldAvg(groupedBodyRecords[key], 'fat_percent') * 100 * 100) / 100
        chartWeights[chartDataIdx] = Math.round(getDataFieldAvg(groupedBodyRecords[key], 'weight') * 100) / 100
      }
    })

    this.chartLabels = Object.values(chartLabels)
    this.chartFatPercents = chartFatPercents
    this.chartWeights = chartWeights
  }

  private prepareMeals (meals: IMeal[]): IDashboardMeal[] {
    if (!meals?.length) {
      return []
    }

    return meals.map(meal => ({
      ...meal,
      created_date_time: dayjs.unix(meal.created_at).format('MM.DD'),
      meal_type_name: meal.meal_type as unknown as IMealType
    }))
  }
}
