import { decodeJwt, HealthApi, gql, formatResponse, formatErrorResponse } from '../apis'
import type { IUser, IUserInput } from '../models'
import { storageService } from '../storage'
import type { IStorageService, IAuthService, IGraphqlApi } from '../interfaces'

export class AuthService implements IAuthService {
  private readonly tokenKey: string
  private _user?: IUser
  private _expiredAt = 0

  constructor (
    tokenKey = '__token',
    private readonly api: IGraphqlApi = HealthApi,
    private readonly storage: IStorageService = storageService
  ) {
    this.tokenKey = tokenKey || '__token'
  }

  get user (): IUser | undefined {
    if (this._user && this._expiredAt && this._expiredAt < now()) {
      return this._user
    }

    const token = this.storage.getItem(this.tokenKey)

    this.parseToken(token)

    return this._user
  }

  async login (input: IUserInput) {
    try {
      const response = await this.api.mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          email: input.email,
          password: input.password
        }
      })
      const token = response.data?.login?.token
      let user

      if (token) {
        this.setToken(token)
        user = this.user
      }

      return formatResponse({ data: user, errors: response.errors }, !token ? ['no token provided'] : undefined)
    } catch (err: any) {
      return formatErrorResponse(err)
    }
  }

  async logout () {
    try {
      this._user = undefined
      this._expiredAt = 0
      this.setToken()

      return { data: {}, errors: [] }
    } catch (err: any) {
      return formatErrorResponse(err)
    }
  }

  private setToken (token?: string) {
    if (!token) {
      this.storage.removeItem(this.tokenKey)
    } else {
      this.storage.setItem(this.tokenKey, token.replace('Bearer ', ''))
    }
  }

  private parseToken (token?: string | null) {
    if (!token) {
      this._user = undefined
      this._expiredAt = 0

      return
    }

    const data = decodeJwt(token)

    if (!data?.exp || !data.email || data.exp < now()) {
      this._user = undefined
      this._expiredAt = 0

      return
    }

    this._user = { email: data.email }
    this._expiredAt = data.exp
  }
}

const now = () => Math.floor(Date.now() / 1000)

const LOGIN_MUTATION = gql`mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
  }
}`
