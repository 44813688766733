import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import { type IListingMode } from './interfaces'

export const today = dayjs()

export const dateFromUnixTimestamp = (unix: number, format = 'YYYY.MM.DD HH:mm'): string => {
  return unix ? dayjs.unix(unix).format(format) : ''
}

export const groupItems = <T>(data: T[], keyBy: (item: T) => string): Record<string, T[]> => {
  return data.reduce<Record<string, T[]>>((groups, item) => {
    const groupName = keyBy(item)
    const group = groups[groupName] || []

    group.push(item)

    groups[groupName] = group

    return groups
  }, {})
}

export const groupItemsByCreatedAt = <T>(data: T[], mode: IListingMode = 'year') => {
  return groupItems<T>(data, item => {
    const day = dayjs.unix((item as any).created_at)

    switch (mode) {
      case 'week':
        return day.format('YYYY-MM-DD')
      case 'month':
        return day.format('YYYY-MM-DD')
      default: // 'year
        return day.format('YYYY-MM')
    }
  })
}

export const getGroupRangeByMode = (mode: IListingMode) => {
  const range: Record<string, string> = {}
  const currentDate = dayjs()
  let unit: 'day' | IListingMode
  let count: number

  switch (mode) {
    case 'week':
      unit = 'day'
      count = 7
      for (let i = count - 1; i >= 0; i--) { // from - to
        const date = currentDate.subtract(i, unit)
        const name = date.locale('ja').format('dddd')
        const value = date.format('YYYY-MM-DD')

        range[value] = name
      }
      break
    case 'month':
      unit = 'day'
      count = 30
      for (let i = count - 1; i >= 0; i--) { // from - to
        const date = currentDate.subtract(i, unit)
        const name = date.locale('ja').format('D日')
        const value = date.format('YYYY-MM-DD')

        range[value] = name
      }
      break

    default: // year
      unit = 'month'
      count = 12
      for (let i = count - 1; i >= 0; i--) { // from - to
        const date = currentDate.subtract(i, unit)
        const name = date.locale('ja').format('MMMM')
        const value = date.format('YYYY-MM')

        range[value] = name
      }
      break
  }

  return range
}

export const getDataFieldAvg = <T = any>(arr: T[], field?: keyof T): number => {
  const sum = arr.reduce<number>((sum, item) => {
    const itemValue = (field ? item[field] : item) as number

    return sum + itemValue
  }, 0)

  return sum / arr.length
}

export const listingModes: Record<IListingMode, string> = {
  week: '週',
  month: '月',
  year: '年'
}
export const listingModeValues = Object.keys(listingModes) as IListingMode[]
export const listingModeNames: string[] = Object.values(listingModes)
