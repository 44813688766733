import React from 'react'
import type { IProps } from '../interfaces'
import classnames from 'classnames'
import PageHeader from './PageHeader'
import styled from 'styled-components'
import { Breakpoints, lg } from '../controls/responsive'
import PageFooter from './PageFooter'

const PageLayout: React.FC<IProps> = ({ children }) => {
  const pageConstructorName = (children as any).type?.name || ''
  const pageClassName: string = pageConstructorName.replace(/Page$/, '')
    .replace(/([A-Z\d])/g, '-$1')
    .toLowerCase()
    .slice(1)

  return (
    <PageLayoutContainer className={classnames('layout layout--page', pageClassName && `layout--page__${pageClassName}`)}>
      <PageHeader />
      <main className='page-main' role='main'>
        {children}
      </main>
      <PageFooter />
    </PageLayoutContainer>
  )
}

const PageLayoutContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .navbar__container, .footer__nav {
    margin: 0 auto;
    width: 100%;
    max-width: ${Breakpoints.lg}px;

    padding: 0 1rem;
    ${lg('padding: 0')}
  }

  .page-header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    box-shadow: 0 2px 2px -2px #000000fa;
  }

  .page-main {
    margin: 0 auto;
    margin-top: 64px;
    width: 100%;
    flex: 1;

    ${lg('padding: 0')}

    section {
      max-width: ${Breakpoints.lg}px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 1rem;
    }
  }
`

export default PageLayout
