import { makeAutoObservable, runInAction } from 'mobx'
import type { IUser } from '../lib/models'
import type { IAuthStore, ILoginResult } from './interfaces'
import type { IAuthService } from '../lib/interfaces'

export class AuthStore implements IAuthStore {
  errors: string[] = []
  user?: IUser
  loginCallbackUrl = '/'

  constructor (private readonly service: IAuthService, user?: IUser) {
    this.user = user

    makeAutoObservable(this)
  }

  clearErrors () {
    this.errors = []
  }

  setLoginCallbackUrl (url: string) {
    this.loginCallbackUrl = url
  }

  async logout () {
    await this.service.logout()
    runInAction(() => {
      this.user = undefined
    })
  }

  async login (email: string, password: string): Promise<ILoginResult> {
    this.errors = []

    if (!email || !password) {
      this.errors.push('please provide credentials')

      return 'failed'
    }

    const result = await this.service.login({
      email,
      password
    })

    if (!result.errors.length) {
      runInAction(() => {
        this.user = this.service.user
      })

      return 'success'
    }

    this.errors = result.errors

    return 'failed'
  }
}
