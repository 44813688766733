import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client'
import type { IApiResult, IGraphqlApi } from '../interfaces'
import { storageService } from '../storage'
import { envName, gqlApi } from '../../config/app'

const httpLink = new HttpLink(gqlApi[envName])
const middlewareLink = new ApolloLink((operation, forward) => {
  const token = storageService.getItem('__token')

  // ensure auth token sent along
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  })

  return forward(operation).map((response) => {
    if (hasError(response.errors)) {
      const firstError = response.errors[0]
      const extensions = firstError?.extensions
      const code = extensions?.code

      if (code === 'UNAUTHORIZED') { // remove auth token when unauthorized calls detected
        storageService.removeItem('__token')
      }
    }

    return response
  })
})

export const HealthApi: IGraphqlApi = new ApolloClient<any>({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache()
})

export { gql } from '@apollo/client'

export const hasError = (errors: any): errors is any[] => {
  return errors?.length > 0
}

export const formatErrors = (errors: any): string[] => {
  if (!hasError(errors)) {
    return []
  }

  const firstError = errors[0]
  const extensions = firstError?.extensions
  const code = extensions?.code

  if (code === 'BAD_USER_INPUT' && extensions.fields) { // server validation field errors detected
    return Object.values(extensions.fields).flat() as string[] // merge field errors
  } else {
    return [firstError.message].filter(Boolean)
  }
}

export const formatResponse = <T = any>(response: any, fallbackErrors?: string[]): IApiResult<T> => {
  const { data, errors } = response
  const fetchErrors = formatErrors(errors)

  return {
    data: data || {},
    errors: fetchErrors.length ? fetchErrors : fallbackErrors || []
  }
}

export const formatErrorResponse = <T = any>(error: any): IApiResult<T> => {
  const serverErrors = error?.networkError?.result?.errors || []
  const errors = serverErrors.length ? serverErrors : [error]

  return {
    data: {} as unknown as T,
    errors: formatErrors(errors)
  }
}
