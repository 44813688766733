import { type IAppStore } from '../config/interfaces'
import { dateFromUnixTimestamp } from '../lib/helpers'
import type { IColumnService } from '../lib/interfaces'
import type { IColumnStore, IColumnStoreItem, IColumnType } from './interfaces'
import { makeAutoObservable, runInAction } from 'mobx'

export const ColumnTypes: Record<string, IColumnType> = {
  all: {
    id: null,
    name: 'RECOMMENDED COLUMN',
    description: 'オススメ'
  },
  diet: {
    id: 1,
    name: 'RECOMMENDED DIET',
    description: 'ダイエット'
  },
  beauty: {
    id: 2,
    name: 'RECOMMENDED BEAUTY',
    description: '美容'
  },
  health: {
    id: 3,
    name: 'RECOMMENDED HEALTH',
    description: '健康'
  }
}

export class ColumnStore implements IColumnStore {
  items: IColumnStoreItem[] = []
  listErrors: string[] = []
  nextCursor = ''

  private _columnTypeId: number | null = null

  constructor (private readonly store: IAppStore, private readonly service: IColumnService) {
    makeAutoObservable(this)
  }

  get hasMore () {
    return Boolean(this.nextCursor)
  }

  get columnTypeId () {
    return this._columnTypeId || null
  }

  async setColumnType (columnTypeId?: number | null) {
    columnTypeId = columnTypeId || undefined // only number or undefined

    if (columnTypeId !== this._columnTypeId) {
      this._columnTypeId = columnTypeId || null // to be used in fetchItems
      await this.list() // reset items without cursor
    }
  }

  async list (after?: string) {
    const { items, errors, nextCursor } = await this.fetchItems(after)

    runInAction(() => {
      this.items = items.map(item => ({
        ...item,
        created_date_time: dateFromUnixTimestamp(item.created_at),
        tag_names: item.tags?.split(/\s*,\s*/) || []
      }))
      this.listErrors = errors

      if (nextCursor) {
        this.nextCursor = nextCursor
      }
    })

    return !errors.length
  }

  async next () {
    if (!this.hasMore) {
      return false
    }

    const { items, errors, nextCursor } = await this.fetchItems(this.nextCursor)

    runInAction(() => {
      this.items = this.items.concat(items.map(item => ({
        ...item,
        created_date_time: dateFromUnixTimestamp(item.created_at),
        tag_names: item.tags?.split(/\s*,\s*/) || []
      })))
      this.listErrors = errors
      this.nextCursor = nextCursor
    })

    return !errors.length
  }

  private async fetchItems (after?: string) {
    this.listErrors = []

    const { data, after: nextCursor, errors } = await this.service.list(after, this._columnTypeId || undefined)

    if (!errors.length) {
      return {
        nextCursor: after !== nextCursor ? nextCursor || '' : '',
        items: data || [],
        errors
      }
    } else {
      return {
        nextCursor: '',
        items: [],
        errors
      }
    }
  }
}
