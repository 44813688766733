import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import classNames from 'classnames'
import { globalHistory } from '@reach/router'
import { usePageHeaderAnimator } from './animations'
import Logo from '../../assets/images/logo.svg'
import MemoIcon from '../../assets/images/icons/memo.svg'
import MenuIcon from '../../assets/images/icons/bars.svg'
import { sm } from '../controls/responsive'
import { store } from '../contexts'

const PageHeader = () => {
  const { auth } = store()
  const animator = usePageHeaderAnimator()
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setIsMenuVisible(false)
    })
  }, [isMenuVisible])

  return (
    <header ref={animator} className='page-header'>
      <Navbar className='page-navbar'>
        <div className='navbar__container'>
          <h1 className='navbar__logo'>
            <Link to='/'>
              {/* <GatsbyImage className='logo__img' image={Logo} alt='logo' /> */}
              <Logo className='logo__img' alt='logo' />
            </Link>
          </h1>

          <section className='navbar__right'>
            <ul className='navbar__menu' role='menu'>
              <li className='menu__item'>
                <Link className='item__link' to='/my/records' partiallyActive activeClassName='item__link--active'>
                  <MemoIcon alt='自分の記録アイコン' className='item__icon' />
                  自分の記録
                </Link>
              </li>
              <li className='menu__item'>
                <div className={classNames('item__dropdown', isMenuVisible && 'item__dropdown--active')}>
                  <MenuIcon
                    alt='自分の記録アイコン'
                    className='item__icon dropdown__toggle'
                    onClick={() => { setIsMenuVisible(!isMenuVisible) }}
                  />
                  <ul className='dropdown__list'>
                    <li className='dropdown__item'>
                      <Link className='item__link' to='/my/records' partiallyActive activeClassName='item__link--active'>
                        自分の記録
                      </Link>
                    </li>
                    <li className='dropdown__item'>
                      <Link className='item__link' to='/my/records' partiallyActive activeClassName='item__link--active'>
                        体重グラフ
                      </Link>
                    </li>
                    <li className='dropdown__item'>
                      <Link className='item__link' to='/my/top' partiallyActive activeClassName='item__link--active'>
                        トップ
                      </Link>
                    </li>
                    <li className='dropdown__item'>
                      <Link className='item__link' to='/' activeClassName='item__link--active'>
                        コラム一覧
                      </Link>
                    </li>
                    {auth.user && (
                      <li className='dropdown__item'>
                        <div
                          className='item__link' onClick={() => {
                            void auth.logout().finally(() => {
                              setIsMenuVisible(false)
                              void navigate('/')
                            })
                          }}
                        >
                          ログアウト
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </Navbar>
    </header>
  )
}

const Navbar = styled.nav`
  display: flex;
  flex-direction: row;
  background-color: #414141;
  height: 64px;

  .navbar__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  .navbar__logo {
    flex-grow: 1;
    flex-basis: 140px;
    margin-right: 1.5rem;
    padding: 0.5rem 0;
  }

  .logo__img {
    width: 100px;
    height: 100%;
  }

  .navbar__right {
    margin-left: auto;
    flex-grow: 0;
    flex-shrink: 1;
    opacity: 0;
  }

  .navbar__menu {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    gap: 1.25rem;

    .menu__item > .item__link {
      display: none;

      ${sm('display: block')}
    }

    .item__icon {
      vertical-align: bottom;
      height: 1.5rem;
      cursor: pointer;

      &:hover path {
        stroke: #ffaf68;
      }
    }

    .item__link {
      font-size: 1rem;
      line-height: 1.5rem;
      color: #F0F0F0;
      cursor: pointer;
      margin-right: 1.5rem;
      text-decoration: none;

      &:hover {
        color: #FFF;
      }
    }

    .item__link--active {
      color: #FF963C;
    }

    .item__link:last-child {
      margin-right: 0;
    }

    .dropdown__list {
      position: absolute;
      right: 0;
      padding: 0;
      margin: 0;
      list-style: none;
      top: 64px;
      background: #777777;
      color: #FEFEFE;
      min-width: 150px;
      z-index: 100;
      box-shadow: 0 2px 2px 0px #00000033;

      display: none;
    }

    .dropdown__item {
      padding: 1rem;
      border-bottom: solid 1px #2E2E2E66;
    }

    .item__dropdown--active {
      .dropdown__toggle {
        path {
          transition: all .2s ease-in-out;
        }

        path:nth-of-type(1) {
          transform: rotate(45deg) translateY(25%);
          transform-origin: center;
        }

        path:nth-of-type(2) {
          opacity: 0;
        }

        path:nth-of-type(3) {
          transform: rotate(-45deg) translateY(-25%);
          transform-origin: center;
        }
      }

      .dropdown__list {
        display: block;
      }
    }
  }
`

export default PageHeader
