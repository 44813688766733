import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import ScrollIcon from '../../assets/images/icons/scroll.svg'

const scrollVariants = {
  initial: { y: '.5rem', opacity: 0 },
  animate: {
    y: '0rem',
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
}
const PageScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false)
  // Show button when page is scrolled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 150) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }
  // Set the top coordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  // the scroll event fires when the document view has been scrolled
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <PageScrollTopContainer className='page-scroll-top'>
      <AnimatePresence>
        {isVisible && (
          <motion.button
            onClick={scrollToTop}
            variants={scrollVariants}
            initial='initial'
            animate='animate'
            exit='initial'
          >
            <ScrollIcon className='scroll_top__icon' />
          </motion.button>
        )}
      </AnimatePresence>

    </PageScrollTopContainer>

  )
}

const PageScrollTopContainer = styled.div`
  position: fixed;
  right: 1.25rem;
  bottom: 3rem;

  button {
    padding: 0;
    background: transparent;
    border: 0;
  }

  .scroll_top__icon {
    path:first-child {
      fill-opacity: 0.85;
      fill: white;
    }

    &:hover {
      cursor: pointer;
      path:first-child {
        fill-opacity: 1;
        fill: #FF963C;
        stroke: white;
      }

      path:last-child {
        fill: white;
      }
    }
  }
`

export default PageScrollTop
