import React from 'react'
import styled from 'styled-components'
import { lg } from '../controls/responsive'
import PageScrollTop from './PageScrollTop'

const PageFooter: React.FC = () => {
  return (
    <PageFooterContainer className='page-footer'>
      <nav className='footer__nav' role='menu'>
        <ul className='nav__items'>
          <li className='nav__item'>会員登録</li>
          <li className='nav__item'>運営会社</li>
          <li className='nav__item'>利用規約</li>
          <li className='nav__item'>個人情報の取扱について</li>
          <li className='nav__item'>特定商取引法に基づく表記</li>
          <li className='nav__item'>お問い合わせ</li>
        </ul>
      </nav>
      <PageScrollTop />
    </PageFooterContainer>
  )
}

const PageFooterContainer = styled.footer`
  background-color: #414141;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-top: 1.5rem;

  .nav__items {
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 1rem;
    color: #FEFEFE;
    flex-wrap: wrap;
    font-weight: 200;

    ${lg('padding: 0 1rem')}
  }
`

export default PageFooter
