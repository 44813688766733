import { makeAutoObservable, runInAction } from 'mobx'
import { getDataFieldAvg, getGroupRangeByMode, groupItemsByCreatedAt } from '../lib/helpers'
import type { IListingMode, ITrainingLevel, IUserService } from '../lib/interfaces'
import type { IBodyRecord, IDiary, IExerciseRecord } from '../lib/models'
import type { IRecordDiary, IRecordExercise, IRecordStore } from './interfaces'
import type { IAppStore } from '../config/interfaces'
import dayjs from 'dayjs'

const groupRangeLabels: Record<IListingMode, string[]> = {
  year: Object.values(getGroupRangeByMode('year')),
  month: Object.values(getGroupRangeByMode('month')),
  week: Object.values(getGroupRangeByMode('week'))
}

// For demonstration only, a i18n lib could be better
const trainingLevels: Record<ITrainingLevel, string> = {
  medium: '中程度の',
  light: '軽い',
  heavy: '重い'
}

export class RecordStore implements IRecordStore {
  errors: string[] = []

  diaries: IRecordDiary[] = []
  readonly hasMoreDiaries = false
  private nextDiaryCursor = ''

  bodyRecords: IBodyRecord[] = []
  _bodyRecordListingMode: IListingMode = 'year'
  bodyRecordChartLabels: string[] = groupRangeLabels.year
  bodyRecordChartRanges: string[] = []
  bodyRecordChartFatPercents: Array<number | undefined> = []
  bodyRecordChartWeights: Array<number | undefined> = []

  exerciseRecords: IRecordExercise[] = []
  exerciseRecordRanges: string[] = []

  constructor (private readonly store: IAppStore, private readonly service: IUserService) {
    makeAutoObservable(this)
  }

  get bodyRecordListingMode () {
    return this._bodyRecordListingMode
  }

  set bodyRecordListingMode (listingMode: IListingMode) {
    this._bodyRecordListingMode = listingMode

    void this.getBodyRecords()
  }

  async getMyRecordsData () {
    this.errors = []

    const { data, errors } = await this.service.getMyRecordsData()

    this.errors = errors

    if (!errors.length) {
      const bodyRecords = data?.bodyRecords || []

      runInAction(() => {
        this.diaries = this.prepareDiaries(data?.diaries?.data)
        this.exerciseRecords = this.prepareExerciseRecords(data?.exerciseRecords)
        this.nextDiaryCursor = data?.diaries?.after || ''
        this.bodyRecords = bodyRecords

        this.prepareBodyRecordChartData(bodyRecords)
      })
    }
  }

  async getBodyRecords () {
    this.errors = []

    const { data, errors } = await this.service.getBodyRecords(this.bodyRecordListingMode)

    this.errors = errors

    if (!errors.length) {
      const bodyRecords = data || []

      runInAction(() => {
        this.bodyRecords = bodyRecords

        this.prepareBodyRecordChartData(bodyRecords)
      })
    }
  }

  async loadMoreDiaries () {
    if (!this.hasMoreDiaries) {
      return
    }

    this.errors = []

    const { data, errors } = await this.service.listDiaries(this.nextDiaryCursor)

    this.errors = errors

    if (!errors.length) {
      runInAction(() => {
        this.diaries = this.diaries.concat(this.prepareDiaries(data || []))
      })
    }
  }

  private prepareBodyRecordChartData (bodyRecords: IBodyRecord[]) {
    const groupedBodyRecords = groupItemsByCreatedAt(bodyRecords, this.bodyRecordListingMode)
    const chartLabels = getGroupRangeByMode(this.bodyRecordListingMode)
    const chartKeys = Object.keys(chartLabels)
    const chartFatPercents = Array(chartKeys.length).fill(undefined)
    const chartWeights = Array(chartKeys.length).fill(undefined)

    Object.keys(groupedBodyRecords).forEach(key => {
      const chartDataIdx = chartKeys.indexOf(key)

      if (chartDataIdx > -1) {
        chartFatPercents[chartDataIdx] = Math.round(getDataFieldAvg(groupedBodyRecords[key], 'fat_percent') * 100 * 100) / 100
        chartWeights[chartDataIdx] = Math.round(getDataFieldAvg(groupedBodyRecords[key], 'weight') * 100) / 100
      }
    })

    this.bodyRecordChartLabels = Object.values(chartLabels)
    this.bodyRecordChartFatPercents = chartFatPercents
    this.bodyRecordChartWeights = chartWeights
    this.bodyRecordChartRanges = [chartKeys.shift(), chartKeys.pop()].filter(Boolean) as string[]
  }

  private prepareDiaries (diaries: IDiary[]): IRecordDiary[] {
    if (!diaries?.length) {
      return []
    }

    return diaries.map(meal => ({
      ...meal,
      created_date_time: dayjs.unix(meal.created_at).format('YYYY.MM.DD HH:mm')
    }))
  }

  private prepareExerciseRecords (records: IExerciseRecord[]): IRecordExercise[] {
    if (!records?.length) {
      return []
    }

    const mappedRecords = records.map(record => ({
      ...record,
      name: `${record.exercise.name} (${trainingLevels[record.exercise.level as unknown as ITrainingLevel]})`,
      kcal_burnt: record.exercise.kcal_burn_rate * record.duration
    }))

    const exerciseRecordRanges = mappedRecords.slice()

    this.exerciseRecordRanges = [
      exerciseRecordRanges.shift()?.created_at,
      exerciseRecordRanges.pop()?.created_at
    ].filter(Boolean).map(createdAt =>
      createdAt && dayjs.unix(createdAt).format('YYYY.MM.DD')
    ) as string[]

    return mappedRecords
  }
}
