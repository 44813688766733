module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-64911368eb/0/cache/gatsby-plugin-styled-components-npm-6.11.0-c502b4e601-47de243cc4.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-2a24e4da7c/0/cache/gatsby-plugin-manifest-npm-5.11.0-857fd9ff94-785b9c333d.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"21aaf9daffdf498492c16d905442488f"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-707af7d762/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
