import { HealthApi, formatResponse, gql } from '../apis'
import { type IGraphqlApi, type IListingMode, type IMealType, type IMyRecordsData, type IUserService } from '../interfaces'
import type { IBodyRecord, IMeal } from '../models'

export class UserService implements IUserService {
  constructor (private readonly api: IGraphqlApi = HealthApi) {}

  async getDashboardData () {
    try {
      const response = await this.api.query({
        query: GET_DASHBOARD_QUERY,
        variables: {
          first: 16
        }
      })

      return {
        ...formatResponse({
          data: {
            meals: {
              data: response.data?.meals?.edges?.map((e: any) => e.node) as IMeal[],
              after: response.data?.meals?.pageInfo?.hasNextPage
                ? response.data?.meals?.pageInfo?.endCursor
                : ''
            },
            bodyRecords: response.data?.bodyRecords?.items as IBodyRecord || []
          },
          errors: response.errors
        })
      }
    } catch (err: any) {
      return formatResponse({
        data: [],
        errors: [err]
      })
    }
  }

  async listMeals (after?: string, mealTypes: IMealType[] = []) {
    try {
      const response = await this.api.query({
        query: LIST_MEALS_QUERY,
        variables: {
          first: 16,
          after: after || '',
          mealTypes: mealTypes || undefined
        }
      })

      return {
        ...formatResponse({
          data: response.data?.meals?.edges?.map((e: any) => e.node) as IMeal[],
          errors: response.errors
        }),
        after: response.data?.meals?.pageInfo?.hasNextPage
          ? response.data?.meals?.pageInfo?.endCursor
          : ''
      }
    } catch (err: any) {
      return formatResponse({
        data: [],
        errors: [err]
      })
    }
  }

  async getMyRecordsData (mode: IListingMode = 'year') {
    try {
      const response = await this.api.query({
        query: GET_MY_RECORDS_DATA,
        variables: {
          first: 16,
          mode
        }
      })

      return {
        ...formatResponse<IMyRecordsData>({
          data: {
            diaries: {
              data: response.data?.diaries?.edges?.map((e: any) => e.node) as IMeal[],
              after: response.data?.diaries?.pageInfo?.hasNextPage
                ? response.data?.diaries?.pageInfo?.endCursor
                : ''
            },
            bodyRecords: response.data?.bodyRecords?.items as IBodyRecord || [],
            exerciseRecords: response.data?.exerciseRecords?.items || []
          },
          errors: response.errors
        })
      }
    } catch (err: any) {
      return formatResponse({
        data: [],
        errors: [err]
      })
    }
  }

  async listDiaries (after?: string) {
    try {
      const response = await this.api.query({
        query: LIST_DIARIES_QUERY,
        variables: {
          first: 16,
          after: after || ''
        }
      })

      return {
        ...formatResponse({
          data: response.data?.diaries?.edges?.map((e: any) => e.node) || [],
          errors: response.errors
        }),
        after: response.data?.diaries?.pageInfo?.hasNextPage
          ? response.data?.diaries?.pageInfo?.endCursor
          : ''
      }
    } catch (err: any) {
      return formatResponse({
        data: [],
        errors: [err]
      })
    }
  }

  async getBodyRecords (mode: IListingMode) {
    try {
      const response = await this.api.query({
        query: GET_BODY_RECORDS,
        variables: {
          first: 16,
          mode
        }
      })

      return formatResponse<IBodyRecord[]>({
        data: response.data?.bodyRecords?.items || [],
        errors: response.errors
      })
    } catch (err: any) {
      return formatResponse({
        data: [],
        errors: [err]
      })
    }
  }
}

const GET_DASHBOARD_QUERY = gql`query GetDashboardData($first: Int!, $mode: BodyRecordListingMode) {
  meals(first: $first) {
    edges {
      node {
        id
        name
        created_at
        meal_type
        photo_url
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  bodyRecords(listing_mode: $mode) {
    items {
      created_at
      weight
      fat_percent
    }
  }
}`

const LIST_MEALS_QUERY = gql`query ListMeals($first: Int!, $mealTypes: [MealType], $after: String) {
  meals(first: $first, meal_types: $mealTypes, after: $after) {
    edges {
      node {
        id
        name
        created_at
        meal_type
        photo_url
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}`

const GET_MY_RECORDS_DATA = gql`query GetMyRecordsData($mode: BodyRecordListingMode, $first: Int!) {
  bodyRecords(listing_mode: $mode) {
    items {
      created_at
      weight
      fat_percent
    }
  }
  exerciseRecords {
    items {
      id
      created_at
      duration
      exercise {
        name
        kcal_burn_rate
        level
      }
    }
  }
  diaries(first: $first) {
    edges {
      node {
        id
        created_at
        content
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}`

const LIST_DIARIES_QUERY = gql`query ListDiaries($first: Int!, $after: String) {
  diaries(first: $first, after: $after) {
    edges {
      node {
        id
        created_at
        content
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}`

const GET_BODY_RECORDS = gql`query GetBodyRecords($mode: BodyRecordListingMode!) {
  bodyRecords(listing_mode: $mode) {
    items {
      created_at
      weight
      fat_percent
    }
  }
}`
