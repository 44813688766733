import { AuthStore } from '../auth/store'
import { AuthService } from '../lib/services/AuthService'
import { ColumnService } from '../lib/services/ColumnService'
import type { IColumnStore } from '../columns/interfaces'
import { ColumnStore } from '../columns/store'
import type { IAppServices, IAppStore } from './interfaces'
import { UserService } from '../lib/services/UserService'
import type { IDashboardStore } from '../dashboard/interfaces'
import { DashboardStore } from '../dashboard/store'
import { type IRecordStore } from '../records/interfaces'
import { RecordStore } from '../records/store'

export const appServices: IAppServices = {
  auth: new AuthService(),
  column: new ColumnService(),
  user: new UserService()
}

export class AppStore implements IAppStore {
  auth = new AuthStore(appServices.auth, appServices.auth.user)
  column: IColumnStore = new ColumnStore(this, appServices.column)
  dashboard: IDashboardStore = new DashboardStore(this, appServices.user)
  record: IRecordStore = new RecordStore(this, appServices.user)
}

export const appStore = new AppStore()
