import { createGlobalStyle } from 'styled-components'

import 'normalize.css'
import HiraginoKakuGothicPro from '../assets/fonts/HiraginoKakuGothicProW3.otf'
import Inter from '../assets/fonts/Inter-VariableFont_slnt,wght.ttf'

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Hiragino Kaku Gothic Pro W3';
    src: url(${HiraginoKakuGothicPro}) format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    src: url(${Inter}) format('truetype');
  }

  html {
    scroll-behavior: smooth;
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    font-family: Hiragino Kaku Gothic Pro W3, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
  }

  .text--data {
    font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  #gatsby-focus-wrapper {
    overflow: hidden;
  }
`
